*{
  box-sizing: border-box;
}

body{
  color:#393939;
}

a:hover{
  opacity: 0.7;
}

.section-content{
  max-width: 1300px;
  margin:0 auto;
}

@media screen and (max-width: 900px) {

  .section-content{
    max-width: 95%;
    margin:0 auto;
  }

  .pc{
    display:none !important;
  }
}